@import 'react-datepicker/dist/react-datepicker';
// Here you can add other styles
.cursor-pointer {
  cursor: pointer;
}
.c-app {
  font-size: .815rem;
}
.c-avatar{
  font-size: inherit;
}
.c-main {
  padding-top: 1rem;
}
.c-main > .container-fluid,
.c-main > .container-sm,
.c-main > .container-md,
.c-main > .container-lg,
.c-main > .container-xl,
.c-main > .container-xxl {
  padding-right: 20px;
  padding-left: 20px;
}
.c-sidebar-nav-link:hover {
  color: theme-color("info");
}
.rotate-90 {
  transform: rotate(-90deg);

  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.form-control-bold {
  font-size: 18px;
  font-weight: bold;
}
.bg-gradient-primary {
  background: $info !important;
  background: linear-gradient(45deg, #418cfd 0%, $info 100%) !important;
  border-color: $info !important;
}
.bg-gradient-info {
  background: $primary !important;
  background: linear-gradient(45deg, #321fdb 0%, $primary 100%) !important;
  border-color: $primary !important;
}
.help-block {
  font-size: 85%;
}
.settings-roles-table thead th {
  padding-bottom: 30px;
}
.btn-warning,
.btn-warning:hover {
  color: #fff;
}
.c-main .card .card-header,
.c-main .card .card-header h1,
.c-main .card .card-header h2,
.c-main .card .card-header h3 {
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.15rem;
  padding: 0.45rem 1rem;
}
.c-main .card-body{
  padding: 0.45rem .75rem;
}
.retouch-check-input {
  height: calc(0.75em + 0.5rem + 2px);
  position: relative;
  margin-top: 0.2rem;
}
.retouch-form-control {
  height: inherit;
}
.ql-container {
  height: auto !important;
  border-bottom-left-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  background: #fefcfc;
}

.ql-editor {
  min-height: 18em;
  overflow-y: scroll;
  resize: vertical;
}
.coupon-form .ql-editor {
  min-height: 32em;
}
/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}
.pre {
  max-height: 450px;
  display: block;
  padding: 10px 30px;
  margin: 0;
  overflow: scroll;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.c-avatar {
  width: auto;
  height: auto;
  background-color: $primary;
  color: #eaecec;
  padding: 0 10px 5px 10px;
}

.c-header-nav-item .codes-checker {
  background-color: #3399ff;
  color: #eaecec !important;
  padding: 5px 10px;
  border-radius: 999px;
}
.c-header-nav-item .codes-checker:hover,
.c-header-nav-item .codes-checker.active {
  background-color: #0d6efd;
}
.checkcodes.card-body {
  padding-top: 0;
}
.checkcodes .c-datatable-filter input {
  width: 30%;
}
.checkcodes .table th,
.checkcodes .table td {
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
}
.checkcodes .table tr.p-0 td {
  border-top: none;
}
form label, .modal label{
  font-weight: bold;
}
form .custom-control label, .custom-control label{
  font-weight: normal;
}
.notes-container, .maneuvers-container{
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 500px;
  max-height: 500px;
  padding-right: 10px;
}
.c-datatable-filter input{
  padding: 20px;
  font-size: 16px;
}
.tab-content>.tab-pane{
  padding: 0 10px;
}
.c-body .btn-sm{
  padding: 0.15rem 0.25rem;
}
.c-main, .c-main textarea {
  white-space: pre-line;
}
.c-main .card-body{
  min-height: 100px;
}
.projects .card-body {
  min-height: 160px;
}
.badge-warning {
  color: #EEE;
}
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-left: 76px;
}
.c-header .c-header-nav .c-header-nav-link.active{
  color: #666;
  font-weight: bold;
}
.header-searchbox{
  font-size: 18px;
  line-height: 24px;
}
.check-curent-date{
  height: 100%;
}
.react-datetime-picker__wrapper{
  border-radius: 5px;
  padding: 0.25rem;
  
  border-color: #d8dbe0!important;
}
.help-block {
  font-style: italic;
}
.customers-table .position-relative{
  min-height: 500px!important;
}
.modal-footer {
  justify-content: flex-start;
}
.header-nav-notes, .header-nav-notifications{
  overflow-y: auto;
  max-height: 680px;
  max-width: 1024px;
}
.liked .ci-primary{
  fill: #e55353;
}
.react-datepicker-popper{
  z-index: 99;
}
table.timekeeping {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

table.timekeeping td,
table.timekeeping th {
  padding: 0.5rem 1rem;
}
table.timekeeping thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background-color: #f1f2f3;
  border-bottom: 1px solid #d8dbe0;
  text-align: center;
}
table.timekeeping thead th:nth-child(2){
  text-align: left;
}
table.timekeeping td {
  background: #fff;
  text-align: center;
  border-top: 1px solid #d8dbe0;
  border-left: 1px solid #d8dbe0;
}
table.timekeeping tbody th, table.timekeeping tfoot th {
  text-align: left;
  position: relative;
  border-top: 1px solid #d8dbe0;
}
table.timekeeping thead th:first-child, table.timekeeping tbody th:first-child, table.timekeeping tfoot th:first-child {
  width: 40px;
}
table.timekeeping thead th:nth-child(2), table.timekeeping tbody th:nth-child(2),
table.timekeeping tfoot th:nth-child(2){
  width: 160px;
}
table.timekeeping thead th:first-child, table.timekeeping thead th:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.timekeeping tbody th, table.timekeeping tfoot th {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f1f2f3;
}
table.timekeeping thead th:nth-child(2), table.timekeeping tbody th:nth-child(2), table.timekeeping tfoot th:nth-child(2){
  left: 40px;
}
table.timekeeping tbody tr:hover td, table.timekeeping tbody tr:hover th{
  background-color: #ebedef;
}
table.timekeeping thead th:last-child, table.timekeeping tbody td:last-child{
  background-color: #f1f2f3;
}
table.timekeeping tfoot th, table.timekeeping tfoot td{
  background-color: rgb(245, 209, 162);
  position: sticky;
  bottom: 0;
}
.timekeeping-container {
  width: 100%;
  max-height: 60vh;
  overflow: auto;
}
.hover-cover .hover-item{
  display: none;
}
.hover-cover:hover .hover-item{
  display: inline-block;
}
.pagination {
  margin-top: 1em;
}
.form-control{
  padding: 0 0.375rem 0 0.375rem;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}
.dropzone:active, .dropzone:hover{
  border-color: #3399ff;
}
.book_form .rdrDefinedRangesWrapper{
  width: 156px;
}
.popover {
  max-width: 100%!important;;
  width: 400px!important;;
  min-width: 400px ! important;
}
.accounting_books-container {
  width: 100%;
  max-height: 60vh;
  overflow: auto;
}
table.accounting_books {
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  border: solid 1px #CCC;
  width: 100%;
  z-index: 1;
  margin: auto;
}
table.accounting_books th, table.accounting_books td {
  padding: 0.3rem 0.75rem;
}
table.accounting_books thead{
  position: sticky;
  top: 0;
  z-index: 2;
}
table.accounting_books thead th{
  border: solid 1px #CECECE;
  text-align: center;
  vertical-align: middle;
  background-color: #f1f2f3;
  background-clip: padding-box;
}
table.accounting_books tbody td{
  border-collapse: collapse;
    border: dotted 1px #CCC;
}
table.accounting_books tbody tr:hover{
  background: #f1f2f3;
}

table.accounting_books tfoot {
  background-color: #f1f2f3;
  position: sticky;
  bottom: 0;
}

table.recruitmentkpi td{
  vertical-align: middle;
}
table.recruitmentkpi tfoot{
  background-color: #f1f2f3;
}